<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-file-document-edit
        </v-icon>Add Change Order
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1 text-left">
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="changeOrderName"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Change Order Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="description"
                  class="field-style"
                  filled
                  auto-grow
                  rows="2"
                  label="Descripion"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="quantity"
                  class="field-style"
                  label="Quantity*"
                  type="number"
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="Total work hours required, eg - 300 Hrs"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="unitCost"
                  class="field-style"
                  type="number"
                  label="Unit Cost*"
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="Per hour rate, eg - $20"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <br>
          <p class="info-text">
            <span class="nb-text">Nb:- Unit will be 'hr' by default</span> <br>
            *Indicates required field
          </p>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="createBudgetChangeOrder()"
            >
              Create
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';

export default {
 name: 'CreateChangeOrder',
  components: {
    'centre-spinner': spinner,
  },
  data () {
    return {
      showModal: true,
      isFormValid: false,
      loading: false,
      changeOrderName: '',
      quantity: null,
      unitCost: null,
      description: '',
    };
  },
watch: {
  showModal: function (newval, oldval) {
    if (newval === false) {
      this.$emit('closed');
    }
  },
},
methods: {
  async createBudgetChangeOrder () {
    if (this.$refs.form.validate() === false) {
      this.$store.dispatch('alert/onAlert', {
      message: 'Please fill the required fields before proceeding.',
      type: Constants.ALERT_TYPE_ERROR,
    }, { root: true });
      return;
    }
    this.loading = true;
    await this.$store.dispatch('projects/createBudgetChangeOrder', {
      details: {
        name: this.changeOrderName,
        description: this.description,
        quantity: parseInt(this.quantity),
        unit: 'hr',
        unit_cost: parseInt(this.unitCost),
      },
      budgetId: this.$route.params.budgetId,
    }).then(response => {
      this.$store.dispatch('alert/onAlert', {
      message: 'New budget change order created successfully.',
      type: Constants.ALERT_TYPE_SUCCESS,
    });
      this.loading = false;
      this.$emit('success');
      this.showModal = false;
    }).catch(() => {
      this.loading = false;
    });
  },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
  margin-bottom: 10px;
}
.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
.field-style {
  margin-top: -20px;
}
.info-text {
  margin-left: 10px;
}
.nb-text {
  color: #37474F;
  font-style: italic;
  font-family: 'Times New Roman', Times, serif;
}
</style>
