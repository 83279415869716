<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      color="#aaa"
      class="my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left
      </v-icon>
      Back
    </v-btn>
    <div v-if="showBudgetDetails">
      <p class="project-header">
        Project Name:- <font
          class="project-name"
        >
          {{ budgetDetails.project.name }}
        </font>
      </p>
    </div>
    <v-row class="mt-5">
      <v-col
        cols="12"
        md="9"
      >
        <v-row class="mb-5">
          <v-col cols="3">
            <div class="budget-card-small budget-card-small-one">
              <div class="d-flex align-center">
                <div class="budget-card-small-icon budget-card-small-one-icon">
                  <v-icon>mdi-currency-usd</v-icon>
                </div>
                <div><h4>Projected Budget</h4><h1> {{ budgetDetails.projected_budget }}<span class="currency-style">{{ budgetDetails.project?.currency }}</span></h1></div>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="budget-card-small budget-card-small-two">
              <div class="d-flex align-center">
                <div class="budget-card-small-icon budget-card-small-two-icon">
                  <v-icon>mdi-currency-usd</v-icon>
                </div>
                <div><h4>Projected Cost</h4><h1>{{ budgetDetails.projected_cost }} <span class="currency-style">{{ budgetDetails.project?.currency }}</span></h1></div>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="budget-card-small budget-card-small-three">
              <div class="d-flex align-center">
                <div class="budget-card-small-icon budget-card-small-three-icon">
                  <v-icon>mdi-currency-usd</v-icon>
                </div>
                <div><h4>Variance Total</h4><h1> {{ budgetDetails.variance_total }} <span class="currency-style">{{ budgetDetails.project?.currency }}</span></h1></div>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="budget-card-small budget-card-small-four">
              <div class="d-flex align-center">
                <div class="budget-card-small-icon budget-card-small-four-icon">
                  <v-icon>mdi-clock-time-eight-outline</v-icon>
                </div>
                <div><h4>Timesheet Hours</h4><h1> {{ budgetDetails.time_sheet_hours }} </h1></div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-tabs
          v-model="tabs"
        >
          <v-tab>
            <v-icon class="mr-2">
              mdi-account-multiple-check-outline
            </v-icon>Check-Ins
          </v-tab>
          <v-tab>
            <v-icon class="mr-2">
              mdi-file-document-plus-outline
            </v-icon>Change Orders
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <budget-check-in-records />
          </v-tab-item>
          <v-tab-item>
            <budget-change-orders
              @change-order-done="getBudgetDetails"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-row
          class="cost-items"
        >
          <v-col
            cols="12"
            md="12"
          >
            <v-card
              class="budget-card"
              color="ECEFF1"
              shaped
              hover
            >
              <v-card-title class="mb-5">
                Budget
              </v-card-title>
              <p class="budget-content">
                Budget Name:- <font
                  class="font-my-style"
                >
                  {{ budgetDetails.name }}
                </font>
              </p>
              <p class="budget-content">
                High Risk Work:- <font
                  :color="getHrwColor(budgetDetails.high_risk_work)"
                >
                  {{ budgetDetails.high_risk_work | displayHighRisk }}
                </font>
              </p>
              <p class="budget-content">
                Projected Work Hours:- <font
                  class="font-my-style"
                >
                  {{ budgetDetails.quantity }} Hrs
                </font>
              </p>
              <p class="budget-content">
                Status:- <font
                  v-if="budgetDetails.status !== null"
                  :color="getStatusColor(budgetDetails.status)"
                >
                  {{ budgetDetails.status | formatStatusVal }}
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-card
              class="budget-date-card"
              color="ECEFF1"
              shaped
              hover
            >
              <v-card-title class="mb-5">
                Budget Duration
              </v-card-title>
              <p class="budget-content">
                Planned Start Date:- <font
                  v-if="budgetDetails.planned_start_date !== null"
                  class="font-my-style"
                >
                  {{ budgetDetails.planned_start_date | convertToLocal }}
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
              <p class="budget-content">
                Planned End Date:- <font
                  v-if="budgetDetails.planned_end_date !== null"
                  class="font-my-style"
                >
                  {{ budgetDetails.planned_end_date | convertToLocal }}
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
              <p class="budget-content">
                Actual Start Date:- <font
                  v-if="budgetDetails.actual_start_date !== null"
                  class="font-my-style"
                >
                  {{ budgetDetails.actual_start_date | convertToLocal }}
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
              <p class="budget-content">
                Actual End Date:- <font
                  v-if="budgetDetails.actual_end_date !== null"
                  class="font-my-style"
                >
                  {{ budgetDetails.actual_end_date | convertToLocal }}
                </font>
                <font
                  v-else
                  class="font-my-style"
                >
                  N/A
                </font>
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>

<script>
  import BudgetCheckInRecords from './BudgetCheckInRecords.vue';
  import BudgetChangeOrders from './BudgetChangeOrders.vue';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import Constants from 'src/constants';
  import global from 'src/mixins/global';

  export default {
    name: 'BudgetSecondNavDrawer',
    components: {
      'budget-check-in-records': BudgetCheckInRecords,
      'budget-change-orders': BudgetChangeOrders,
      'centre-spinner': spinner,
    },
    filters: {
      displayHighRisk (status) {
        if (status === true) {
          return 'Yes*';
        } else {
          return 'No';
        }
      },
      formatStatusVal (val) {
        if (val === 'active') {
          return 'Active*';
        } else if (val === 'pending') {
          return 'Pending';
        } else if (val === 'completed') {
          return 'Completed*';
        } else {
          return val;
        }
      },
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY');
      },
    },
    mixins: [global],
    data: () => ({
      selectedItem: -1,
      showBudgetCheckInRecords: true,
      showBudgetChangeOrders: false,
      loading: false,
      tabs: null,
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
      budgetDetails () {
        return this.$store.getters['projects/getBudgetDetails'];
      },
      showBudgetDetails () {
        if (Object.keys(this.budgetDetails).length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      this.loading = true;
      this.selectedItem = 0;
      await this.getBudgetDetails();
      this.loading = false;
    },
    methods: {
      back () {
        this.$store.dispatch('projects/storeProjectSecondNavDrawerTab', {
          toTab: 3,
        });
        const projectId = this.$route.params.projectId;
        this.$router.push(`/projects/${projectId}/details`);
      },
      async getBudgetDetails () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchBudgetDetails', {
          budgetId: this.$route.params.budgetId,
        });
        this.loading = false;
      },
      getDifferenceColor (val) {
        if (val === 0) {
          return '#2E7D32';
        }
        return '#C62828';
      },
      getHrwColor (value) {
        if (value === true) return '#C62828';
        else { return '#2E7D32'; }
      },
      getStatusColor (status) {
        if (status === 'completed' || status === 'active') {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
      getBudgetCheckInRecords () {
        this.showBudgetChangeOrders = false;
        this.showBudgetCheckInRecords = true;
      },
      getBudgetChangeOrders () {
        if (this.budgetDetails.database === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Change orders are only allowed in budgets made with our software.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.showBudgetCheckInRecords = false;
        this.showBudgetChangeOrders = true;
      },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.my-back-btn-style {
  margin-bottom: -20px;
}
@media (max-width: 959px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
  .budget-card {
    margin-left: -20px;
  }
  .budget-date-card {
    margin-top: -20px;
    margin-left: -20px;
  }
  .budget-cost-card {
    margin-top: -20px;
    margin-left: -20px;
  }
  .project-header {
    margin-left: 20px;
  }
}
@media (min-width: 959px) and (max-width: 1245px) {
  .font-my-style {
  font-weight: bold;
  font-size: 12px !important;
  color: #000;
  }
  .budget-content {
  font-size: 12px !important;
  font-weight: bold;
  color: #546E7A;
  margin-left: 12px !important;
  line-height: 0.8;
  }
}
.drawer-style {
  font-size:25px;
  color:#37474F;
  font-weight:bold;
}
.drawer-item-style {
  font-size:14px !important;
  color:#37474F !important;
  font-weight:bold !important;
}
.type-style {
  text-transform: capitalize;
  font-weight: bold;
}
.my-style {
  margin-bottom: 10px !important;
}
.my-hrw-style {
  text-transform: capitalize;
  font-weight: bold;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.status-font-style {
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  font-weight: bold;
}
.job-status-style {
  text-transform: capitalize;
  font-weight: bold;
}
.budget-content {
  font-size: 14px;
  font-weight: bold;
  color: #546E7A;
  margin-left: 15px;
  line-height: 1.2;
}
.font-my-style {
  font-weight: bold;
  font-size: 14px;
  color: #000;
}
.difference-font {
  font-weight: bold;
  font-size: 14px;
}
.budget-card {
  background: #e2d9f7;
  border: 1px solid #a077ff !important;
  padding: 30px;
  cursor: default;
  border: none;
  border-radius: 10px !important;
  box-shadow: none !important;
  margin: 0px !important;
}
.budget-cost-card {
  background: #cfe5eb;
  border: 1px solid #2bc6f1 !important;
  padding: 30px;
  cursor: default;
  border: none;
  border-radius: 10px !important;
  box-shadow: none !important;
  margin: 0px !important;
}
.budget-date-card {
  background: #ffe0bb;
  border: 1px solid #c57d27 !important;
  padding: 30px;
  cursor: default;
  border: none;
  border-radius: 10px !important;
  box-shadow: none !important;
  margin: 0px !important;
}
.project-header {
  margin-top: 25px;
  margin-bottom: -20px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
}
.project-name {
  color: #FF3700;
}
.cost-items {
  margin-left: 0px;
}
.currency-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
}
.budget-card-small{
  border-radius: 10px;
  padding: 20px;
}
.budget-card-small-icon {
  margin-right: 10px;
  background: #ccc;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.budget-card-small h1{
  font-size: 24px;
  font-weight: bold;
}
.budget-card-small h4 {
  font-size: 14px;
  color: #aaa;
  font-weight: normal;
}
.budget-card-small-one {
  border: 1px solid #5AB3D9 !important;
}
.budget-card-small-one-icon {
  background:#5AB3D9 !important;
}
.budget-card-small-two {
border: 1px solid #8DC548  !important;
}
.budget-card-small-two-icon {
background: #E3FBC5 !important;
}
.budget-card-small-three {
border: 1px solid #6E94CE  !important;
}
.budget-card-small-three-icon {
background: #96BDF8 !important;
}
.budget-card-small-four {
border: 1px solid #96F8E6  !important;
}
.budget-card-small-four-icon {
background: #96F8E6 !important;
}
.premium-style {
  font-size:25px;
  color:#006883!important;
  font-weight:bold;
}
.premium-container{
  background-color: #ffffff;
  border-radius: 50%;
}
</style>
