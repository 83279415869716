export default {
    methods: {
        permissionCheck (permissionName) {
            const storedUserDetails = localStorage.getItem('scp-user');
            const userDetails = JSON.parse(storedUserDetails);
            if (userDetails.user.permissions === null || userDetails.user.permissions.length === 0) {
                return false;
            } else {
                if (userDetails.user.permissions.some(e => e.name === permissionName)) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        roleCheck (roleName) {
            const storedUserDetails = localStorage.getItem('scp-user');
            const userDetails = JSON.parse(storedUserDetails);
            if (userDetails.user.role !== null && userDetails.user.role.name === roleName) {
                return true;
            } else {
                return false;
            }
        },
        partnerCheck () {
            const storedUserDetails = localStorage.getItem('scp-user');
            const userDetails = JSON.parse(storedUserDetails);
            if (userDetails.user.organization.partner !== null) {
                return true;
            } else {
                return false;
            }
        },
        accessCheck (level) {
            const storedUserDetails = localStorage.getItem('scp-user');
            const userDetails = JSON.parse(storedUserDetails);
            if (userDetails.user.access_level === level) {
                return true;
            } else {
                return false;
            }
        },
        subscriptionCheck (status) {
            const storedUserDetails = localStorage.getItem('scp-user');
            const userDetails = JSON.parse(storedUserDetails);
            if (userDetails.user.organization.subscription_status === status) {
                return true;
            } else {
                return false;
            }
        },
    },
  };
